/*  Fix for copy cell in react-data-grid showing up */

.react-grid-cell-copied {
    display: none;
}

.react-grid-Main {
    height: 100%;
}

.react-grid-Container {
    height: 100%;
}

.react-grid-Grid {
    min-height: 100% !important;
}

.react-grid-Canvas {
    height: 100% !important;
}

.react-grid-HeaderCell,
.react-grid-Cell {
    box-sizing: border-box;
}

.rdg-editor-container {
    z-index: 10051 !important;
}


/* global */

html {
    font-family: consolas, tahoma, verdana, arial, sans-serif;
    font-size: 14px;
    color: #141823;
}

.react-csv-input {
    padding: 2px;
    /* display: block; */
    margin: 2px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}


/* .editor {
    border: "1px solid #ccc";
    cursor: "text";
    min-height: 80;
    padding: 10
  } */

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner {
    line-height: normal !important;
}